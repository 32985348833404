import type { NuxtError } from '#app'
import type { COUNTRY_SHORTS } from '~/constants'

export default function useDeliveryCost() {
  const deliveryCostPending = useDeliveryCostPending()
  const deliveryCostData = useDeliveryCostData()
  const deliveryCostError = useDeliveryCostError()

  const { $rentals } = useNuxtApp()

  async function fetchDeliveryCost({
    rvId,
    rvCountry,
    rvAllowCrossBorderTravel,
    rvHasDelivery,
    destination,
    allowMaximumDistanceOverride,
  }: {
    rvId: string
    rvCountry?: COUNTRY_SHORTS
    rvAllowCrossBorderTravel?: boolean
    rvHasDelivery?: boolean
    destination?: {
      lat?: number
      lng?: number
      country?: COUNTRY_SHORTS
    }
    allowMaximumDistanceOverride?: boolean
  }) {
    if (!rvId || !rvHasDelivery || destination?.lat === undefined || destination?.lng === undefined || !destination?.country) {
      deliveryCostData.value = null
      deliveryCostError.value = null

      return {
        deliveryCostPending,
        deliveryCostData,
        deliveryCostError,
      }
    }

    deliveryCostPending.value = true

    try {
      // Validate cross-boder travel
      if (!rvAllowCrossBorderTravel && destination?.country !== rvCountry) {
        throw createError({
          data: {
            Code: 'CROSS_BORDER_TRAVEL_DISALLOWED',
            Message: 'Cross-border travel is not allowed for this RV',
          },
        })
      }

      const data = await $rentals('/api/rvs/delivery-cost', {
        query: {
          RvId: rvId,
          DestinationLat: destination?.lat,
          DestinationLng: destination?.lng,
          AllowMaxDistanceOverride: allowMaximumDistanceOverride,
        },
      })

      deliveryCostData.value = data
      deliveryCostError.value = null
    }
    catch (error) {
      deliveryCostError.value = createError(error as NuxtError)
      deliveryCostData.value = null
    }
    finally {
      deliveryCostPending.value = false
    }

    return {
      deliveryCostPending,
      deliveryCostData,
      deliveryCostError,
    }
  }

  return {
    deliveryCostPending,
    deliveryCostData,
    deliveryCostError,
    fetchDeliveryCost,
  }
}
